import { List, X } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link, useRouter } from "next-translate-routes"
import styled from "styled-components"
import { signOut } from "next-auth/react"
import Image from "next/image"
import ValvestLogo from "@/public/logo_valvest.svg"
import { FaRegCopy, FaUserFriends } from "react-icons/fa"
import { toast } from "react-toastify"
import {
  Bookmarks,
  BoxArrowLeft,
  CashStack,
  Gear,
  HouseCheck,
  Repeat,
} from "react-bootstrap-icons"
import useComponentVisible from "@/components/common/useComponentVisible"
import { Avatar } from "@mui/material"
import TagManager from "react-gtm-module"
import posthog from "posthog-js"

const AccountDropdown = ({ session }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "orange",
        fontSize: "1rem",
      },
      children: `${name?.split(" ")[0][0]}${name?.split(" ")?.[1]?.[0] || ""}`,
    }
  }

  const router = useRouter()

  const currentPath = router.pathname

  const copied = translate("account_raf_copied")

  const isActive = path => (currentPath === path ? "active" : "")

  return (
    <div ref={ref} style={{ padding: "0.6rem 0" }}>
      {isComponentVisible ? (
        <div className="container position-relative">
          <Dropdown>
            <div className="innerMenu mobile text-start" style={{}}>
              <div className="item pt-0 pb-0 p-4">
                <p className="name mt-0 mb-0 pt-3">
                  {session?.profile && (
                    <>
                      {session?.profile?.firstName +
                        " " +
                        session?.profile?.lastName || null}
                    </>
                  )}
                </p>
                <p className="id">
                  ID: {session?.uid.substring(0, 8)}
                  <FaRegCopy
                    className="ms-1 clickable"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        session?.uid.substring(0, 8)
                      )
                      toast.success(copied)
                    }}
                  />
                </p>
              </div>
              <hr className="p-0 mb-2 mt-0" />
              <div className="menu">
                <Link
                  href="/account/portfolio"
                  onClick={() => setIsComponentVisible(false)}
                  className={`item ${isActive("/account/portfolio")}`}
                >
                  <HouseCheck style={{ marginTop: "-0.2rem" }} />
                  {translate("account_submenu_portfolio")}
                </Link>
                <Link
                  href="/account/balance"
                  onClick={() => setIsComponentVisible(false)}
                  className={`item ${isActive("/account/balance")}`}
                >
                  <CashStack />
                  {translate("account_submenu_balance")}
                </Link>
                <Link
                  href="/account/refer-a-friend"
                  onClick={() => setIsComponentVisible(false)}
                  className={`item ${isActive("/account/refer-a-friend")}`}
                >
                  <FaUserFriends />
                  {translate("account_submenu_raf")}
                </Link>
                <Link
                  href="/account/auto-invest"
                  onClick={() => setIsComponentVisible(false)}
                  className={`item ${isActive("/account/auto-invest")}`}
                >
                  <Repeat />
                  {translate("account_submenu_autoinvest")}
                </Link>
                <hr className="p-0 mt-2 mb-2" />
                <Link
                  href="/account/settings"
                  className={`item ${isActive("/account/settings")}`}
                  onClick={() => setIsComponentVisible(!isComponentVisible)}
                >
                  <Gear />
                  {translate("account_submenu_settings")}
                </Link>
                <div className="referral-box d-flex">
                  <FaUserFriends size={20} className="mt-1" />
                  <div>
                    <p className="mb-0 referral-title">
                      {translate("account_dropdown_raf_title")}
                    </p>
                    <p className="mb-0 mt-1 referral-description">
                      {translate("account_dropdown_raf_description") +
                        " " +
                        session?.referralToken}{" "}
                      <FaRegCopy
                        className="ms-1 clickable"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "https://valvest.io/referral=" +
                              session?.referralToken
                          )
                          toast.success(copied)
                        }}
                      />{" "}
                    </p>
                    <p
                      onClick={() => {
                        router.push("/account/refer-a-friend"),
                          setIsComponentVisible(!isComponentVisible)
                      }}
                      className="mb-0 mt-1 rafLink"
                    >
                      {translate("account_dropdown_raf_button")}
                    </p>
                  </div>
                </div>
                <hr />
                <div
                  className="item"
                  onClick={e => {
                    signOut({ callbackUrl: "/" }), posthog.reset()
                  }}
                >
                  <BoxArrowLeft />
                  {translate("account_submenu_logout")}
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      ) : null}
      <div>
        <div
          className="profile-button"
          role="button"
          onClick={() => {
            setIsComponentVisible(!isComponentVisible),
              TagManager.dataLayer({
                dataLayer: {
                  event: "menu_button_profile",
                  user_id: session?.uid,
                },
              })
          }}
        >
          <List size={20} className="list" />
          {session?.image && session?.provider !== "linkedin" ? (
            <Image
              src={session?.image}
              alt="Avatar"
              width={30}
              height={30}
              className="rounded-circle"
            />
          ) : (
            <Avatar
              style={{
                height: "30px",
                width: "30px",
              }}
              {...stringAvatar(
                session?.profile?.firstName.toUpperCase() +
                  (session?.profile?.lastName || "").toUpperCase()
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountDropdown

const Dropdown = styled.div`
  position: relative;
  max-width: 200px;
  right: 260px;
  top: 55px;
  text-align: left !important;

  .innerMenu {
    background: white;
    position: absolute;
    height: auto; /* Changed to auto for dynamic height */
    width: 310px;
    max-height: 80vh; /* Optional: Set max height for overflow control */
    overflow-y: auto; /* Enable scrolling if content exceeds max-height */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid #eee;
    z-index: 99999;

    hr {
      padding: 0;
      margin: 0 0 0rem;
    }
  }

  .menu .item {
    padding: 0.5rem 1.5rem !important;
    color: rgba(18, 20, 23, 0.75);
    cursor: pointer;
    font-size: 0.95rem;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &.active {
      background: rgba(
        0,
        0,
        0,
        0.05
      ); /* Highlighted background for active item */
      font-weight: 500;
    }
  }

  .referral-box {
    background: #f8f9fa;
    padding: 1rem;
    font-size: 0.95rem;
    border-radius: 10px;
    margin: 0.5rem;
    background-color: #fceed0;
    color: rgba(18, 20, 23, 0.75) !important;

    .referral-title {
      font-weight: 600;
      font-size: 0.95rem;
      color: rgba(18, 20, 23, 0.75) !important;
    }

    .referral-description {
      font-weight: 300;
    }

    .rafLink {
      color: #136fe8;
      font-weight: 300;
      cursor: pointer;
    }
  }

  svg {
    margin-right: 0.75rem;
    margin-top: -0.2rem;
  }

  .name {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .id {
    margin-top: 0;
    font-size: 0.9rem;
    color: rgba(18, 20, 23, 0.5) !important;
    cursor: default;
  }

  .closeMobile {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  hr {
    color: #a4a4a4;
  }
`
