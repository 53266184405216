import {
  Button,
  CircularProgress,
  Dialog,
  InputAdornment,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { Check, Gift, X } from "react-bootstrap-icons"
import { handleCode } from "./actions"
import translate from "@/utils/translation"
import styled from "styled-components"

function BonusModal(props) {
  const { onClose, selectedValue, open, account, setAccount } = props
  const [errors, setErrors] = useState({
    token: "",
  })

  const [token, setToken] = useState()

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
  }

  const handleClose = () => {
    if (errors.token) {
      setAccount({ ...account, token: "" })
    }

    onClose(selectedValue)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="p-4">
        <Back onClick={handleClose} className="clickable" size={24} />
        <TextField
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            },
          }}
          value={token}
          style={{ zIndex: 0, margin: 0, width: "100%" }}
          helperText={
            errors?.token ? (
              <span style={{ color: "red" }}>{errors.token}</span>
            ) : (
              ""
            )
          }
          error={!!errors.token} // This applies the red border
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Gift
                  color={
                    errors?.token
                      ? "red"
                      : account.token?.length > 0
                      ? "#FA8503"
                      : "#d2d5d9"
                  }
                  className="ms-2"
                  size={15}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {account?.tokenLoader ? (
                  <CircularProgress size={20} />
                ) : errors?.token ? (
                  <p
                    className="error mt-3"
                    onClick={() => {
                      setToken(),
                        setErrors({
                          ...errors,
                          token: "",
                        })
                    }}
                  >
                    <X
                      size={30}
                      color="rgb(211, 47, 47)"
                      className="clickable"
                    />
                  </p>
                ) : null}
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setToken(e.target.value)

            if (e.target.value?.length === 0) {
              setErrors({
                ...errors,
                token: "",
              })
            }
          }}
          label={
            <div className="d-flex">
              {translate("register_referral")}
              <span className="ms-1 smaller">
                ({translate("register_referral_optional")})
              </span>
            </div>
          }
          variant="outlined"
          fullWidth
          className="mt-4"
        />
        <div className="d-flex">
          <Button
            variant="contained"
            fullWidth
            className="mt-3"
            onClick={() =>
              handleCode({
                account,
                setAccount,
                setErrors,
                errors,
                translation,
                handleClose,
                token,
              })
            }
          >
            {translate("listing_overview_filters_apply")}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default BonusModal

const Back = styled(X)`
  position: absolute;
  left: 10px;
  top: 12px;
  cursor: pointer;
`
