import styled from "styled-components"
import { useSession } from "next-auth/react"
import MenuDesktop from "./mainMenu/menuDesktop"
import MenuMobile from "./mainMenu/menuMobile"
import DiscountModal from "./discountModal/discountModal"
import RegisterModal from "../../auth/Register/RegisterModal"
import AppointmentModal from "./appointmentModal/AppointmentModal"
import WebinarModal from "./webinarModal/webinarModal"

const HeaderNavigation = () => {
  const { data: session } = useSession()

  return (
    <Navbar className="headerBar">
      <MenuDesktop session={session} />
      <MenuMobile session={session} />
      <DiscountModal />
      <RegisterModal />
      {/* <AppointmentModal /> */}
      <WebinarModal />
    </Navbar>
  )
}

export default HeaderNavigation

const Navbar = styled.div`
  background-color: white;
  position: relative;

  margin-top: 90px;
  width: 100%;

  z-index: 10;

  .chevron {
    margin-top: 0.4rem;
  }

  .nav-item {
    :first-child {
      margin-left: 2rem;
    }

    padding: 2rem 1.5rem 2rem 1.5rem;
    margin: auto 0;
  }

  .mobile-menu {
    padding-top: 0.6rem;
    padding-right: 1rem;
    cursor: pointer;
  }

  .nav-button {
    font-size: 1rem;
    padding: 0.7rem 1.4rem;
    margin-top: -5px;
    color: white;
  }

  a {
    color: #333;
    text-decoration: none;

    :hover {
      color: #8a8a8a;
      text-decoration: none;
    }
  }

  .profile-button {
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border: 1px solid #dddddd;
    border-radius: 75px;
    display: flex;

    svg {
      margin-top: 5px;
    }

    .list {
      margin-right: 10px;
    }

    :hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
    }
  }

  // dropdown content

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    position: absolute;
    top: 55px;
    left: -140px;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 0.875rem;

      :hover {
        background-color: #f9f9f9;
      }
    }
  }

  .dropdown-listings {
    position: absolute;
    top: 55px;
    left: -140px;
    background-color: white;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-size: 0.875rem;

      :hover {
        background-color: #f9f9f9;
      }
    }
  }

  .mobile {
    .item {
      display: block;
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
      cursor: pointer;

      :hover {
        color: #fb8500;
        border-radius: 5px;
      }
    }
  }

  @media (max-width: 992px) {
    margin-top: 65px !important;
  }
`
