import { handleNewContact, handleNewLead } from "@/lib/strapi/userServices"
import axios from "axios"
import { signIn } from "next-auth/react"
import posthog from "posthog-js"
import { toast } from "react-toastify"

export const handleAccount = async ({
  setAccount,
  account,
  setErrors,
  translation,
  router,
}) => {
  try {
    setAccount({ ...account, loading: true })

    const errorsFound = validate({ setErrors, account, translation })

    // FIX: Check if validation failed instead of using `.some()`
    if (!errorsFound) {
      setAccount({ ...account, loading: false })
      return
    }

    const response = await axios.get(
      `/api/users?email=${account?.email.toLowerCase()}`
    )

    if (response?.data?.exists) {
      const res = await signIn("credentials", {
        redirect: false,
        username: account?.email.toLowerCase(),
        password: account?.password,
      })

      if (res?.status === 200) {
        toast.success(translation.login_succesful_title, {
          position: "bottom-right",
        })

        setAccount({ ...account, loading: false })
      } else {
        setAccount({ ...account, loading: false })

        setErrors({ password: translation.auth_password })

        return
      }

      router.push("/listings")
    } else {
      posthog.capture("pre_sign_up", {
        email: account?.email,
      })

      const user = {
        ...account,
        locale: router?.locale?.toLowerCase() || "EN",
      }

      axios.post(`/api/users/onboarding`, user)

      setAccount({ ...account, step: 2 })
    }
  } catch (error) {
    console.error("Error handling account:", error)
    setAccount({ ...account, loading: false })
  }
}

const validate = ({ setErrors, account, translation }) => {
  let newErrors = {}

  const isValidEmail = /^[\w.-]+@[\w.-]+\.\w{2,3}$/.test(account.email)
  if (!isValidEmail) {
    newErrors.email = translation.auth_email
  }

  if (!account.password) {
    newErrors.password = translation.auth_password
  } else {
    const hasValidLength = account.password.length >= 8

    if (!hasValidLength) {
      newErrors.password = translation.auth_password
    }
  }

  setErrors(newErrors)
  return Object.keys(newErrors).length === 0 // Returns a boolean
}

export const Prevalidate = email => {
  const valid = /^[\w.-]+@[\w.-]+\.\w{2,3}$/.test(email) ? true : false

  return valid
}
