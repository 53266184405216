import handleError from "@/lib/errors/handleError"
import axios from "axios"
import { toast } from "react-toastify"

export const handleAccount = async ({
  setAccount,
  account,
  setErrors,
  translation,
}) => {
  try {
    setAccount({ ...account, loading: true })
    const errorsFound = validate({ setErrors, account, translation })

    if (errorsFound.some(error => error.length > 0)) {
      setAccount({ ...account, loading: false })
      return
    }

    const response = await axios
      .post(`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/auth/forgot-password`, {
        email: account?.email,
      })
      .catch(error => {
        handleError(error)

        toast.error(translation.errorWrongEmail)
        setLogin({
          ...login,
          loading: false,
        })
      })

    setAccount({
      email: "",
      loading: false,
    })

    toast.success(translation.authResetSuccess)
  } catch (error) {
    console.error("Error handling account:", error)
    setAccount({ ...account, loading: false })
  }
}

const validate = ({ setErrors, account, translation }) => {
  let tempErrors = {}

  tempErrors.email = /^[\w.-]+@[\w.-]+\.\w{2,3}$/.test(account?.email)
    ? ""
    : translation.auth_email

  setErrors(prev => ({ ...prev, ...tempErrors }))

  return Object.values(tempErrors)
}
