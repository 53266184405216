import { Context } from "@/context/state"
import translate from "@/utils/translation"
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material"
import React, { useContext, useReducer, useState } from "react"
import { ArrowLeft, ChevronRight, Eye, EyeSlash } from "react-bootstrap-icons"
import countries from "@/utils/countries"
import styled from "styled-components"
import { handleAccount, handleCountryChange } from "./actions"
import { MuiPhone } from "@/components/common/phoneInput/phoneInput"
import { Link, useRouter } from "next-translate-routes"
import BonusModal from "./bonusModal"
import { getCookie } from "cookies-next"

const ConfirmForm = ({ account, setAccount, errors, setErrors }) => {
  const [submitted, setSubmitted] = useState(false)
  const [openBonus, setOpenBonus] = React.useState(false)
  const { dispatch } = useContext(Context)
  const router = useRouter()

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
  }

  const validateForm = () => {
    let newErrors = {}
    if (!account.firstName || account.firstName.length < 3)
      newErrors.firstName = translation.auth_required
    if (!account.lastName || account.lastName.length < 3)
      newErrors.lastName = translation.auth_required
    if (!account.country) newErrors.country = translation.auth
    if (!account.phone || account.phone.length < 10)
      newErrors.phone = translation.auth_required

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    setSubmitted(true)

    if (validateForm()) {
      handleAccount({
        account,
        setAccount,
        setErrors,
        router,
        translation,
        dispatch,
      })
    }
  }

  return (
    <Content>
      <div className="header">
        <h1>{translate("account_auth_2_title")}</h1>
        <Back
          onClick={() => {
            setAccount({ ...account, step: 1 }), setErrors()
          }}
          className="clickable"
          size={20}
        />
      </div>

      <div className="content pt-4">
        <div className="">
          <Autocomplete
            options={countries}
            disableClearable
            getOptionLabel={option => `${option.country}`}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                option =>
                  option.country
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.native.toLowerCase().includes(inputValue.toLowerCase())
              )
            }
            value={countries.find(c => c.code === account.country) || null}
            onChange={(_, newValue) =>
              handleCountryChange({ newValue, setAccount, account })
            }
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label={translate("account_my_profile_country")}
                error={submitted && !!errors?.country}
                helperText={submitted && errors?.country}
              />
            )}
          />
        </div>
        <div className="d-flex mt-3 gap-2">
          <TextField
            label={translate("auth_register_firstName")}
            fullWidth
            value={account?.firstName}
            onChange={e =>
              setAccount({ ...account, firstName: e.target.value })
            }
            error={submitted && !!errors?.firstName}
            helperText={submitted && errors?.firstName}
            InputProps={{
              autocomplete: "new-firstName",
              form: {
                autocomplete: "off",
              },
            }}
          />
          <TextField
            label={translate("auth_register_lastName")}
            fullWidth
            value={account?.lastName}
            onChange={e => setAccount({ ...account, lastName: e.target.value })}
            error={submitted && !!errors?.lastName}
            helperText={submitted && errors?.lastName}
            InputProps={{
              autocomplete: "new-lastName",
              form: {
                autocomplete: "off",
              },
            }}
          />
        </div>
        <div className="mt-3">
          <MuiPhone
            error={
              submitted &&
              ((account.phone && account.phone.length < 10) || errors?.phone)
            }
            helperText={submitted && errors?.phone}
            onChange={e => setAccount({ ...account, phone: e })}
            fullWidth
          />
        </div>

        <div className="coupon">
          {account.token ? (
            <p>
              Code:{" "}
              <span className="clickable" onClick={() => setOpenBonus(true)}>
                {account.token}
              </span>
            </p>
          ) : (
            <p>
              <span className="clickable" onClick={() => setOpenBonus(true)}>
                {translate("register_referral")} <ChevronRight size="10" />
              </span>
            </p>
          )}
        </div>
        {openBonus && (
          <BonusModal
            account={account}
            setAccount={setAccount}
            open={openBonus}
            onClose={() => setOpenBonus(false)}
          />
        )}
        <div className="conditions">
          <p>
            {translate("auth_agree_terms_1")}
            <span className="fw-bold">{translate("auth_agree_terms_1_2")}</span>
            {translate("auth_agree_terms_1_3")}
            <Link
              href="/policy/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
              className="condition"
            >
              {translate("auth_agree_terms_2")}
            </Link>
            {" " + translate("auth_agree_terms_3") + " "}
            <Link
              href="/policy/user-terms"
              rel="noopener noreferrer"
              target="_blank"
              className="condition"
            >
              {translate("auth_agree_terms_4")}
            </Link>
            .
          </p>
        </div>
        <Button
          onClick={handleSubmit}
          variant="contained"
          fullWidth
          className="rounded mt-2 p-2"
          disabled={account.loading}
        >
          {!account.loading ? (
            <>{translate("account_auth_2_button")}</>
          ) : (
            <CircularProgress size={26} />
          )}
        </Button>
      </div>
    </Content>
  )
}

export default ConfirmForm

const Back = styled(ArrowLeft)`
  position: absolute;
  left: 24px;
  top: 23px;
  cursor: pointer;
`

const Content = styled.div`
  color: rgb(34, 34, 34);
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 40px);
  overflow: hidden;

  @media (max-width: 600px) {
    max-height: calc(90vh - 20px);
  }

  .header {
    padding: 1.5rem 0 0.5rem;
    text-align: center;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    border-bottom: 1px solid #ddd;

    h1 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0rem 1.5rem 1.75rem;
    max-height: 100%; /* Adjusts for header height */

    h4 {
      font-size: 0.875rem;
      font-weight: 600;
      color: rgb(34, 34, 34);
      margin-bottom: 0.15rem;
    }
  }

  .disclaimer {
    color: #6a6a6a;
    font-size: 0.7rem;
  }

  .coupon {
    font-size: 0.75rem;
    color: rgb(106, 106, 106);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .conditions p {
    font-weight: 400;
    font-size: 0.75rem;
    color: rgb(106, 106, 106);
    margin: 0.5rem 0.5rem 0.5rem 0;

    a {
      color: rgb(106, 106, 106);
      text-decoration: underline;
      font-weight: 400;
    }
  }
  .error-text {
    color: rgb(211, 47, 47);
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
`
