import translate from "@/utils/translation"
import { Link } from "next-translate-routes"
import React, { useState } from "react"
import { ChevronRight } from "react-bootstrap-icons"
import styled from "styled-components"

const JumboLearn = ({ isVisible, setIsJumboLearnActive }) => {
  const [activeItem, setActiveItem] = useState(1)

  return (
    <Wrapper
      isVisible={isVisible}
      onMouseEnter={() => setIsJumboLearnActive(true)}
      onMouseLeave={() => setIsJumboLearnActive(false)}
    >
      <div className="inner container">
        <div className="row pt-3 pb-4">
          <div className="col-4 menu">
            <ul className="menu-items">
              <Link
                href="/blog"
                className={`menu-item d-flex justify-content-between ${
                  activeItem === 1 ? "active" : ""
                }`}
                onMouseEnter={() => setActiveItem(1)}
              >
                {translate("menu_blog")}
                <ChevronRight className="icon" />
              </Link>
              <Link
                href="/helpcenter"
                className={`menu-item d-flex justify-content-between ${
                  activeItem === 3 ? "active" : ""
                }`}
                onMouseEnter={() => setActiveItem(3)}
              >
                {translate("menu_jumbo_learn_faq")}
                <ChevronRight className="icon" />
              </Link>
            </ul>
          </div>
          <div className="col-8 resources">
            {activeItem === 1 && (
              <>
                <p>{translate("menu_jumbo_how_valvest_works_description")}</p>
                <p className="small mt-4">
                  {translate("menu_jumbo_resources")}
                </p>
                <ul className="resource-items">
                  <li>
                    <Link href="/blog/how-it-works">
                      {translate("menu_jumbo_how_valvest_works")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog/why-invest-in-real-estate">
                      {translate("menu_jumbo_why_invest_with_valvest")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog/how-it-works#top-projects-selected-by-experts">
                      {translate("menu_jumbo_how_source_properties")}
                    </Link>
                  </li>
                </ul>
              </>
            )}
            {activeItem === 2 && (
              <>
                <p>{translate("menu_jumbo_learn_description")}</p>
                <p className="small mt-4">
                  {translate("menu_jumbo_resources")}
                </p>
                <ul className="resource-items">
                  <li>
                    <Link href="/blog/why-invest-in-real-estate">
                      {translate("menu_jumbo_learn_why_invest")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog/investing-in-real-estate-as-beginner">
                      {translate("menu_jumbo_learn_little_money")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog/how-we-develop-a-project-at-valvest">
                      {translate("menu_jumbo_learn_how_valvest_develops")}
                    </Link>
                  </li>
                </ul>
              </>
            )}
            {activeItem === 3 && (
              <>
                <p>{translate("menu_jumbo_learn_faq_description")}</p>
                <p className="small mt-4">
                  {translate("menu_jumbo_faq_title")}
                </p>
                <ul className="resource-items">
                  <li>
                    <Link href="/helpcenter/article/213-how-long-until-i-get-my-money-back">
                      {translate("menu_jumbo_faq_how_long_locked")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/helpcenter/article/212-how-much-time-does-it-take-to-manage-my-investments">
                      {translate("menu_jumbo_faq_how_much_time_management")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/helpcenter/article/55-what-investements-do-we-offer">
                      {translate(
                        "menu_jumbo_faq_what_investments_do_valvest_offer"
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link href="/helpcenter/article/105-what-securities-do-i-have-at-valvest">
                      {translate("menu_jumbo_faq_what_securities")}
                    </Link>
                  </li>
                  <li>
                    <Link href="/helpcenter/article/110-do-i-pay-taxes-over-my-interest-income">
                      {translate("menu_jumbo_faq_taxes")}
                    </Link>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default JumboLearn

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 80px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? "translateY(0)" : "translateY(0px)"};
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${props => (props.isVisible ? "auto" : "none")};
  z-index: 90000;

  cursor: default;

  .inner {
    position: relative;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 9999999;
  }

  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .menu {
    background: white;
    padding: 0rem 1.5rem 0 2.5rem;
    flex: 1;

    .menu-items {
      list-style: none;
      padding: 0;
      margin: 0;

      .menu-item {
        padding: 0.5rem 0;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        line-height: 2;
        font-weight: 500;
        font-size: 0.95rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;

        &:last-child {
          border: none;
        }

        .icon {
          margin-top: 0.2rem;
        }

        &.active {
          color: orange;
          font-weight: 400;
        }
      }
    }
  }

  .resources {
    background: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 1rem 1rem;
    flex: 2;

    p {
      margin: 0 0 0.5rem 0;
      font-size: 0.85rem;
    }

    .resource-items {
      list-style: none;
      padding: 0;
      margin: 0;

      li,
      a {
        padding: 0.4rem 0;
        cursor: pointer;
        font-size: 0.75rem;
        color: rgb(21, 108, 216);
        text-decoration: underline;
        font-weight: 300;
      }
    }

    .small {
      font-size: 0.75rem;
      font-weight: 600;
      color: #333;
    }
  }

  @media (min-width: 768px) {
    .row {
      flex-direction: row;
    }
  }
`
