import "react-international-phone/style.css"
import { InputAdornment, TextField } from "@mui/material"
import React from "react"
import {
  defaultCountries,
  FlagImage,
  usePhoneInput,
} from "react-international-phone"
import translate from "@/utils/translation"

export const MuiPhone = ({ value, onChange, ...restProps }) => {
  const { phone, handlePhoneValueChange, inputRef, country } = usePhoneInput({
    defaultCountry: navigator?.language?.slice(0, 2) || "nl",
    countryCallingCodeEditable: false,
    countries: defaultCountries,
    value: value, // Add this to initialize with the provided value
    onChange: ({ phone }) => onChange?.(phone),
  })

  return (
    <TextField
      fullWidth
      variant="outlined"
      color="primary"
      label={translate("account_my_settings_profile_phone")}
      value={phone || ""} // This will now reflect either the controlled value or the internal phone state
      onChange={handlePhoneValueChange}
      type="tel"
      className="pb-0 mb-0"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ mr: "10px", ml: "0px" }}>
            <FlagImage iso2={country.iso2} style={{ display: "flex" }} />
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  )
}
