import { useContext, useEffect } from "react"
import { useSession } from "next-auth/react"
import { useRouter } from "next/router"
import TagManager from "react-gtm-module"
import { getCookie, setCookie } from "cookies-next"
import posthog from "posthog-js"
import { Context } from "@/context/state"

const FacebookPixelInitializer = () => {
  const { data: session, status } = useSession()
  const router = useRouter()

  const { dispatch } = useContext(Context)

  useEffect(() => {
    if (typeof window === "undefined") return

    const consentCookieRAW = localStorage.getItem("consent")
    const consentCookie = consentCookieRAW ? JSON.parse(consentCookieRAW) : {}

    const params = new URLSearchParams(window.location.search)
    const referral = params.get("referral")
    const source = params.get("utm_source")

    const webinar = params.get("webinar")

    if (referral) {
      localStorage.setItem("referral", referral)
    }

    if (source) {
      localStorage.setItem("source", source)
    }

    if (webinar) {
      dispatch({ type: "SET_WEBINAR", payload: true })
    }

    if (
      process.env.NODE_ENV === "production" &&
      process.env.NEXT_PUBLIC_GTM_ID
    ) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        dataLayer: {
          ecommerce: null,
          consent: {
            functional_storage: consentCookie?.functional_storage || "granted",
            ad_storage: consentCookie?.ad_storage || "granted",
            analytics_storage: consentCookie?.analytics_storage || "granted",
            personalization_storage: "granted",
            security_storage: "granted",
          },
        },
      }

      TagManager.initialize(tagManagerArgs)
    }
  }, [router.events, session, status])

  useEffect(() => {
    // If session is still loading, do not run
    if (status === "loading" || typeof window === "undefined") return

    const initializeFacebookPixel = async () => {
      try {
        const {
          default: ReactPixel,
        } = require("@bettercart/react-facebook-pixel")

        const fbc = getCookie("_fbc") || getCookie("_fclid") || undefined
        const fbp = getCookie("_fbp") || undefined

        const click_id =
          new URLSearchParams(window.location.search)?.get("click_id") ||
          undefined

        if (click_id) {
          setCookie("click_id", click_id)
        }

        // Fetch client IP address
        const clientIp = await fetch("https://api.ipify.org?format=json")
          .then(response => response.json())
          .then(data => data.ip)
          .catch(() => undefined)

        const options = {
          autoConfig: true,
          debug: process.env.NODE_ENV !== "production",
        }

        if (status === "authenticated" && session) {
          // Identify user in PostHog
          posthog.identify(session?.uid, {
            email: session?.email,
            first_name: session?.profile?.firstName,
            last_name: session?.profile?.lastName,
            city: session?.profile?.address?.city,
            date_of_birth: session?.profile?.dateofBirth,
            zip_code: session?.profile?.address?.zipcode,
            country: session?.profile?.address?.country,
            phone: session?.profile?.phone,
            click_id: click_id,
          })

          const advancedMatching = {
            em: session?.email || undefined,
            fn: session?.profile?.firstName || undefined,
            ln: session?.profile?.lastName || undefined,
            ct: session?.profile?.address?.city || undefined,
            db: session?.profile?.dateofBirth || undefined,
            zp: session?.profile?.address?.zipcode || undefined,
            country: session?.profile?.address?.country || undefined,
            external_id: session?.uid || undefined,
            currency: "EUR",
            ph: session?.profile?.phone || undefined,
            fbc,
            fbp,
            client_ip_address: clientIp,
            client_user_agent: window.navigator.userAgent,
          }

          if (process.env.NODE_ENV === "production") {
            ReactPixel.init("990060332777096", advancedMatching, options)

            ReactPixel.pageView()
          }
        } else {
          // Unauthenticated user: minimal initialization
          if (process.env.NODE_ENV === "production") {
            ReactPixel.init(
              "990060332777096",
              {
                fbc,
                fbp,
                client_ip_address: clientIp,
                client_user_agent: window.navigator.userAgent,
              },
              options
            )

            ReactPixel.pageView()
          }
        }
      } catch (err) {
        console.error("Failed to initialize Facebook Pixel:", err)
      }
    }

    initializeFacebookPixel()
  }, [session, status])

  useEffect(() => {
    const { default: ReactPixel } = require("@bettercart/react-facebook-pixel")
    // This function will be called whenever the route changes have completed
    const handleRouteChange = () => {
      // Safely call ReactPixel.pageView if available
      if (ReactPixel && typeof ReactPixel.pageView === "function") {
        ReactPixel.pageView()
      }

      // Capture a $pageview event with PostHog
      if (posthog && typeof posthog.capture === "function") {
        posthog.capture("$pageview")
      }
    }

    // This function will be called before the user leaves or refreshes the page
    const handlePageLeave = () => {
      if (posthog && typeof posthog.capture === "function") {
        posthog.capture("$pageleave")
      }
    }

    // Attach the event listeners
    router.events.on("routeChangeComplete", handleRouteChange)
    window.addEventListener("beforeunload", handlePageLeave)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      window.removeEventListener("beforeunload", handlePageLeave)
    }
  }, [router.events])

  return null
}

export default FacebookPixelInitializer
