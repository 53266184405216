import translate from "@/utils/translation"
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { ArrowLeft, Eye, EyeSlash } from "react-bootstrap-icons"
import styled from "styled-components"
import { FcGoogle } from "react-icons/fc"
import { signIn } from "next-auth/react"
import { toast } from "react-toastify"
import { FaFacebook, FaLinkedin } from "react-icons/fa"
import posthog from "posthog-js"

const DifferentProvider = ({ account, setAccount, errors, setErrors }) => {
  const [showPassword, setShowPassword] = useState(false)

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
    login_failed_account_title: translate("login_failed_account_title"),
    login_succesful_title: translate("login_succesful_title"),
  }

  useEffect(() => {
    const handleAuthSuccess = event => {
      if (event.data?.event === "authSuccess") {
        // Proceed to the next step after successful authentication
        posthog.identify(res?.data?.uid, {
          email: session?.email,
        })

        posthog.capture("sign_in", {
          email: session?.email,
        })

        toast.success("Succesvol ingelogd")
      } else if (event.data.event === "new") {
        posthog.capture("pre_sign_up", {
          email: event.data.body.email,
        })

        setAccount({
          ...account,
          email: event.data.body.email,
          step: 2,
          provider: event.data.body?.provider,
          access_token: event.data.body?.access_token,
          image: event.data.body?.image || "",
        })
      } else if (event.data.event === "provider") {
        posthog.capture("other_sign_in", {
          email: event.data.body.email,
        })

        setAccount({
          ...account,
          email: event.data.body.email,
          step: 4,
          provider: event.data.body.provider,
          image: event.data.body.image,
        })
      }
    }

    window.addEventListener("message", handleAuthSuccess)
    return () => window.removeEventListener("message", handleAuthSuccess)
  }, [])

  const validateForm = () => {
    let newErrors = {}

    if (!account.password && account?.provider === "local")
      newErrors.password = translation.auth_validation_error
    if (account?.password?.length < 8 && account?.provider === "local")
      newErrors.password = translation.auth_validation_error

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async () => {
    setAccount({ ...account, loading: true })

    if (validateForm()) {
      const res = await signIn("credentials", {
        redirect: false,
        username: account?.email,
        password: account?.password,
      })
      if (res?.status === 200) {
        posthog.capture("sign_in", {
          email: account?.email,
        })

        toast.success(translation.login_succesful_title, {
          position: "bottom-right",
        })

        setAccount({ ...account, loading: false })
      } else {
        setErrors({
          password: translation.auth_password,
        })

        setAccount({ ...account, loading: false })
      }
    }
  }

  return (
    <Content>
      <div className="header">
        <h1>{translate("account_auth_4_account_exists")}</h1>
        <Back
          onClick={() => {
            setAccount({ step: 1 }), setErrors()
          }}
          className="clickable"
          size={20}
        />
      </div>
      <div className="content pt-4">
        <div className="text-center">
          <p className="disclaimer">{translate("account_auth_3_disclaimer")}</p>
          <div className="d-flex justify-content-center mt-4">
            {account?.image ? (
              <>
                <Avatar
                  style={{
                    height: "90px",
                    width: "90px",
                    backgroundColor: "orange", // Set the background color to orange
                    color: "white", // Ensure text is visible
                    fontSize: "24px", // Adjust text size for better appearance
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  src={account?.image}
                />
              </>
            ) : (
              <Avatar
                style={{
                  height: "90px",
                  width: "90px",
                  backgroundColor: "orange", // Set the background color to orange
                  color: "white", // Ensure text is visible
                  fontSize: "24px", // Adjust text size for better appearance
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "700",
                }}
              >
                {(account?.email?.[0] || "J").toUpperCase()}
              </Avatar>
            )}
          </div>
        </div>
        {account?.provider === "local" && (
          <>
            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              label={"Wachtwoord"}
              className="mt-4"
              value={account?.password}
              onChange={e =>
                setAccount({ ...account, password: e.target.value })
              }
              error={!!errors?.password}
              helperText={errors?.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Eye /> : <EyeSlash />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              fullWidth
              className="rounded mt-3 p-2"
              disabled={account.loading}
            >
              {!account.loading ? (
                <>{translate("login_title")}</>
              ) : (
                <CircularProgress size={26} />
              )}
            </Button>
          </>
        )}
        {account?.provider === "google" && (
          <Button
            variant="outlined"
            className="w-100 mt-3 sso-button"
            onClick={() =>
              window.open(
                "/auth/popup?type=google", // Use NextAuth provider route
                "Google Login",
                "width=600,height=600"
              )
            }
          >
            <div className="logo">
              <FcGoogle size={24} />
            </div>
            <div className="text">
              {translate("account_intro_google")} Google
            </div>
          </Button>
        )}
        {account?.provider === "facebook" && (
          <Button
            variant="outlined"
            className="w-100 mt-3 sso-button"
            onClick={() =>
              window.open(
                "/auth/popup?type=facebook", // Use NextAuth provider route
                "Facebook Login",
                "width=600,height=600"
              )
            }
          >
            <div className="logo">
              <FaFacebook size={24} color="#1877F2" />
            </div>
            <div className="text">
              {translate("account_intro_google")} Facebook
            </div>
          </Button>
        )}
        {account?.provider === "linkedin" && (
          <Button variant="outlined" className="w-100 mt-3 sso-button">
            <div className="logo">
              <FaLinkedin size={24} color="#0D68C2" />
            </div>
            <div className="text">
              {translate("account_intro_google")} LinkedIn
            </div>
          </Button>
        )}
        <div
          className="alternative mt-3 clickable"
          onClick={() => setAccount({ ...account, step: 1 })}
        >
          {translate("account_auth_4_disclaimer")}
        </div>
        {account?.provider === "local" && (
          <div className="alternative mt-3">
            <>{translate("login_button_forgot_password")}</>
          </div>
        )}
      </div>
    </Content>
  )
}

export default DifferentProvider

const Back = styled(ArrowLeft)`
  position: absolute;
  left: 24px;
  top: 23px;
  cursor: pointer;
`

const Content = styled.div`
  color: rgb(34, 34, 34);
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 40px);
  overflow: hidden;

  .header {
    padding: 1.5rem 0 0.5rem;
    text-align: center;
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    border-bottom: 1px solid #ddd;

    h1 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0rem 1.5rem 1.75rem;
    max-height: 100%; /* Adjusts for header height */

    h4 {
      font-size: 0.875rem;
      font-weight: 600;
      color: rgb(34, 34, 34);
      margin-bottom: 0.15rem;
    }
  }

  .disclaimer {
    color: #6a6a6a;
    font-size: 0.875rem;
  }

  .error-text {
    color: rgb(211, 47, 47);
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }

  .alternative {
    color: gray;
    font-size: 0.875rem;
    text-decoration: underline;
  }

  .sso-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 402px;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: rgb(34, 34, 34);

    :hover {
      background-color: #eee;
    }

    .logo {
      position: absolute;
      left: 16px;
      top: 10px;
      display: flex;
      align-items: center;
    }

    .text {
      text-align: center;
      width: 100%;
    }
  }
`
