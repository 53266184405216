import React, { useState } from "react"
import { TextField, InputAdornment, IconButton } from "@mui/material"
import {
  Eye,
  EyeSlash,
  XCircleFill,
  CheckCircleFill,
} from "react-bootstrap-icons"
import styled from "styled-components"
import translate from "@/utils/translation"
import { handleAccount } from "../IntroForm/actions"

const PasswordInput = ({ account, setAccount, setErrors, errors }) => {
  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
  }

  const [showPassword, setShowPassword] = useState(false)
  const [validation, setValidation] = useState({
    length: false,
    numberOrSymbol: false,
    noNameEmail: false,
  })

  const checkPasswordStrength = pwd => {
    if (!pwd) {
      setValidation({
        length: false,
      })
      setErrors(prevErrors => ({ ...prevErrors, password: "" }))
      return false
    }
  }

  const handleChange = e => {
    const newPassword = e.target.value
    setAccount({ ...account, password: newPassword })
    checkPasswordStrength(newPassword)
  }

  return (
    <Container>
      <TextField
        type={showPassword ? "text" : "password"}
        fullWidth
        value={account?.password}
        onChange={handleChange}
        error={!!errors?.password}
        helperText={errors?.password}
        label={translate("login_label_password")}
        onKeyDown={
          account?.email?.length > 5
            ? e =>
                e.key === "Enter"
                  ? handleAccount({
                      setAccount,
                      account,
                      setErrors,
                      translation,
                    })
                  : null
            : null
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Eye /> : <EyeSlash />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`

const Validation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Rule = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: ${({ isValid }) => (isValid ? "green" : "red")};
`

const StrengthIndicator = styled.div`
  font-weight: bold;
  color: ${({ strength }) => {
    switch (strength) {
      case "zwak":
        return "red"
      case "gemiddeld":
        return "orange"
      case "sterk":
        return "green"
      default:
        return "black"
    }
  }};
`

export default PasswordInput
