import translate from "@/utils/translation"
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { ArrowLeft, Eye, EyeSlash } from "react-bootstrap-icons"
import styled from "styled-components"
import { signIn } from "next-auth/react"
import { toast } from "react-toastify"
import { useRouter } from "next-translate-routes"
import { handleAccount } from "./actions"

const ForgotForm = ({ account, setAccount, errors, setErrors, axios }) => {
  const translation = {
    authResetSuccess: translate("auth_restore_email_sent"),
    errorWrongEmail: translate("auth_validation_wrong_email"),
  }

  return (
    <Content>
      <div className="header">
        <h1>{translate("login_button_forgot_password")}</h1>
        <Back
          onClick={() => {
            setAccount({ ...account, step: 1 }), setErrors()
          }}
          className="clickable"
          size={20}
        />
      </div>
      <hr />
      <div className="content">
        <div className="input">
          <TextField
            autoComplete="email"
            error={
              (account?.email?.length > 0 &&
                (account?.email?.length < 6 ||
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    account?.email
                  ))) ||
              errors?.email
            }
            helperText={errors?.email}
            style={{ zIndex: 0 }}
            InputLabelProps={{ shrink: account?.email ? true : false }}
            onChange={e => {
              setAccount({
                ...account,
                email: e.target.value,
              }),
                setErrors()
            }}
            label={translate("auth_register_email")}
            fullWidth
            value={account?.email}
            aria-describedby="emailHelp"
            minLength={6}
          />
        </div>
        <Button
          onClick={() =>
            handleAccount({
              setAccount,
              account,
              setErrors,
              translation,
            })
          }
          onKeyDown={
            account?.email?.length > 5
              ? e =>
                  e.key === "Enter"
                    ? handleAccount({
                        setAccount,
                        account,
                        setErrors,
                        translation,
                      })
                    : null
              : null
          }
          variant="contained"
          fullWidth
          className="rounded mt-3 p-2"
          disabled={account?.loading}
        >
          {account?.loading ? (
            <div className="loading">
              <CircularProgress color="primary" size="20px" className="mt-2" />
            </div>
          ) : (
            <>{translate("account_onboarding_next")}</>
          )}
        </Button>
      </div>
    </Content>
  )
}

export default ForgotForm

const Back = styled(ArrowLeft)`
  position: absolute;
  left: 24px;
  top: 23px;
  cursor: pointer;
`

const Content = styled.div`
  .header {
    padding: 1.5rem 0 0rem;
    text-align: center;

    h1 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .content {
    padding: 1rem 1.5rem 2.75rem;
    h2 {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .divider {
    font-size: 0.875rem;
    color: gray;
  }

  .sso-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 402px;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: rgb(34, 34, 34);

    :hover {
      background-color: #eee;
    }

    .logo {
      position: absolute;
      left: 16px;
      top: 10px;
      display: flex;
      align-items: center;
    }

    .text {
      text-align: center;
      width: 100%;
    }
  }

  .alternative {
    color: gray;
    font-size: 0.875rem;
    text-decoration: underline;
  }
`
