import handleError from "@/lib/errors/handleError"
import axios from "axios"
import { getCookie } from "cookies-next"
import posthog from "posthog-js"
import TagManager from "react-gtm-module"
import { toast } from "react-toastify"
import uuid from "react-uuid"
import { signIn as SignIntoProvider } from "next-auth/react"
import { fetchDiscount } from "@/lib/strapi/userServices"

export const handleCountryChange = ({ newValue, setAccount, account }) => {
  setAccount({
    ...account,
    country: newValue?.code ?? "",
  })
}

export const handleCode = ({
  account,
  setAccount,
  setErrors,
  errors,
  translation,
  handleClose,
  token,
}) => {
  if (token?.length > 0) {
    setAccount({
      ...account,
      tokenLoader: true,
    })
    setErrors({
      ...errors,
      token: "",
    })
    axios
      .get(`/api/discounts?code=${token}`)
      .then(result => {
        if (result?.data?.type === "referral") {
          console.log(result.data.code)

          setAccount({
            ...account,
            token: result?.data?.code,
            discountType: "referral",
            tokenLoader: false,
          })

          toast.success("Referral applied")
          handleClose()
        } else if (result?.data?.type === "discount") {
          console.log(result.data.code)

          setAccount({
            ...account,
            token: result?.data?.code,
            discountType: "discount",
            tokenLoader: false,
          })
          handleClose()

          toast.success("Discount applied")
        } else {
          setAccount({
            ...account,
            tokenLoader: false,
          })
        }
      })
      .catch(() => {
        setErrors({
          ...errors,
          token: translation.auth_token_wrong,
        })
        setAccount({
          ...account,
          tokenLoader: false,
        })
      })
  } else {
    setErrors({
      ...errors,
      token: translation.auth_token_wrong,
    })
  }
}

export const handleAccount = async ({
  account,
  setAccount,
  setErrors,
  router,
  translation,
  dispatch,
}) => {
  try {
    setAccount({ ...account, loading: true })

    const uid = uuid()

    const referralToken = uuid().substr(0, 7).replace(/-/g, "").toUpperCase()

    var createdUser

    if (account?.provider) {
      //provider registration

      var newUser = {
        uid: uid,
        username: account.email,
        image: account.image,
        referralToken: referralToken,
        affiliateId: account?.click_id || getCookie("click_id") || "",
        profile: {
          address: {
            country: account?.country,
          },
          firstName: capitalizeFirstLetter(account?.firstName),
          lastName: capitalizeFirstLetter(account?.lastName),
          notifications: true,
          locale: router?.locale,
          phone: account?.phone,
        },
        accountType: "buyer",
        source: localStorage?.getItem("source") || "direct",
      }

      const result = await axios
        .post(`/api/users/`, {
          user: newUser,
          access_token: account.access_token,
          provider: account.provider,
        })
        .catch(err => {
          console.log("crash")
          handleError(err)
          setAccount({ ...account, loading: false })
        })

      createdUser = result.data
    } else {
      //regular registration

      var newUser = {
        uid: uid,
        email: account.email,
        password: account.password,
        username: account.email,
        referralToken: referralToken,
        affiliateId: account?.click_id || getCookie("click_id") || "",
        profile: {
          address: {
            country: account?.country,
          },
          firstName: capitalizeFirstLetter(account?.firstName),
          lastName: capitalizeFirstLetter(account?.lastName),
          notifications: true,
          locale: router?.locale,
          phone: account?.phone,
        },
        accountType: "buyer",
        source: localStorage?.getItem("source") || "direct",
      }

      createdUser = await axios
        .post(
          `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/auth/local/register`,
          newUser
        )
        .catch(err => {
          handleError(err)
          setAccount({ ...account, loading: false })
        })
    }

    const token = account?.token || localStorage?.getItem("referral")

    if (token) {
      const bonus = await fetchDiscount(token)

      if (bonus?.type === "discount") {
        const discountObject = {
          user: createdUser?.data?.user?.id,
          discount: bonus?.code,
          id: bonus?.id,
        }

        await axios.post(`/api/discounts`, discountObject).catch(err => {
          handleError(err)
        })
      } else if (bonus?.type === "referral") {
        const referralObject = {
          user: createdUser?.data?.user?.id,
          referral: account?.token || referral || "",
          type: "referee",
        }

        await axios.post(`/api/referrals`, referralObject).catch(err => {
          handleError(err)
        })
      }
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "sign_up",
        name: account.firstName + " " + account.lastName,
        email: account.email,
        phone: account.phone,
        uid: uid,
      },
    })

    const fbp = getCookie("_fbp") ? getCookie("_fbp") : null
    const fbc = getCookie("_fbc") ? getCookie("_fbc") : getCookie("_fbclid")

    newUser.fbp = fbp
    newUser.fbc = fbc
    newUser.external_id = uid
    newUser.referral = localStorage.getItem("referral")
      ? localStorage.getItem("referral")
      : null

    const ip = await fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => data.ip)
      .catch(() => null)

    import("@bettercart/react-facebook-pixel")
      .then(x => x.default)
      .then(ReactPixel => {
        ReactPixel.track(
          "CompleteRegistration",
          {
            currency: "EUR",
            external_id: uid ? uid : null,
            fbp: fbp ? fbp : null,
            fbc: fbc ? fbc : null,
            fn: capitalizeFirstLetter(account.firstName),
            ln: capitalizeFirstLetter(account.lastName),
            em: account.email,
            uid: uid ? uid : null,
            ph: account.phone,
            client_ip_address: ip,
            client_user_agent: window?.navigator?.userAgent,
          },
          { eventID: 100 }
        )
      })
      .catch(err => {
        handleError(err)
      })

    posthog.identify(account?.email, {
      firstName: account?.firstName,
      lastName: account?.lastName,
      phone: account?.phone,
    })

    posthog.capture("sign_up", {
      email: account?.email,
      firstName: capitalizeFirstLetter(account?.firstName),
      lastName: capitalizeFirstLetter(account?.lastName),
      phone: account?.phone,
      uid: uid,
      referral: localStorage.getItem("referral")
        ? localStorage.getItem("referral")
        : null,
      external_id: uid,
      fbp: fbp,
      fbc: fbc,
      client_ip_address: ip,
      client_user_agent: window?.navigator?.userAgent,
    })

    try {
      axios.post(`/api/users/facebook`, newUser)
    } catch (error) {
      console.log(error)
    }

    toast.success(translation.auth_new_account_succesfull)

    try {
      if (!account?.provider) {
        await SignIntoProvider("credentials", {
          username: account.email,
          password: account.password,
          redirect: false,
        })
          .then(res => {
            if (res?.status === 200) {
              router.push("/account/onboarding")

              setAccount({ ...account, loading: false })
              dispatch({ type: "HIDE_LOGIN_MODAL" })
            } else {
              toast.error(translation.auth_validation_error)
            }
          })
          .catch(err => {
            handleError(err)
            setAccount({ ...account, loading: false })
          })
      } else {
        await SignIntoProvider("credentials", {
          jwt: createdUser.jwt,
          username: account.email,
          redirect: false,
        })
          .then(res => {
            if (res?.status === 200) {
              //localStorage.removeItem("referral")
              router.push("/account/onboarding")

              setAccount({ ...account, loading: false })
              dispatch({ type: "HIDE_LOGIN_MODAL" })
            } else {
              toast.error(translation.auth_validation_error)
            }
          })
          .catch(err => {
            handleError(err)
            setAccount({ ...account, loading: false })
          })
      }
    } catch (err) {
      handleError(err)
    }
  } catch (err) {
    handleError(err)
    setErrors({ response: err })
    setAccount({ ...account, loading: false })
    return
  }
}

const capitalizeFirstLetter = str => {
  if (!str) return ""
  return str
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}
