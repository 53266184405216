import { Context } from "@/context/state"
import { ClickAwayListener, TextField } from "@mui/material"
import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"

import styled, { keyframes } from "styled-components"
import IntroForm from "./IntroForm/IntroForm"
import axios from "axios"
import ConfirmForm from "./ConfirmForm/ConfirmForm"

import DifferentProvider from "./DifferentProvider.js/DifferentProvider"
import ForgotForm from "./ForgotForm/ForgotForm"
import { toast } from "react-toastify"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

// New animation for mobile bottom sheet effect
const slideUpBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const RegisterModal = () => {
  const { data: session } = useSession()
  const router = useRouter()
  const { state, dispatch } = useContext(Context)
  const [errors, setErrors] = useState()

  const account = state.account

  const setAccount = body => {
    dispatch({ type: "SET_ACCOUNT", payload: body })
  }

  const { register, email } = router.query

  const hasShownToast = useRef(false) // Track if toast was already shown

  const getAccount = useCallback(async () => {
    if (hasShownToast.current) return // Prevent multiple toasts

    try {
      const response = await axios.get(`/api/users/onboarding?email=${email}`)

      if (response.status === 200) {
        setAccount({
          ...account,
          ...response.data,
          step: 2,
          token: "RECOVER25",
        })
        dispatch({ type: "SET_LOGIN_MODAL" })
        router.replace({})
      }
    } catch (error) {
      if (!hasShownToast.current) {
        toast.warning("Already signed up")
        hasShownToast.current = true // Set to true to prevent further toasts
      }
      dispatch({ type: "HIDE_LOGIN_MODAL" })
      router.replace({})
    }
  }, [email, dispatch, router, setAccount])

  useEffect(() => {
    if (register) {
      getAccount()
    }
  }, [register, getAccount]) // Depend only on `register` and `getAccount`

  useEffect(() => {
    if (session) {
      dispatch({ type: "HIDE_LOGIN_MODAL" })
    }

    if (
      typeof window !== "undefined" &&
      !state?.account?.token &&
      !state?.account?.country
    ) {
      setAccount({
        ...state,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        loading: false,
        news: false,
        created: false,
        token: localStorage.getItem("referral") || "",
        step: 1,
        country: navigator?.language?.slice(0, 2).toUpperCase() || "NL",
      })
    }
  }, [session, dispatch, router])

  const clickAwayRef = useRef(false)

  if (state.registerModal) {
    return (
      <Modal>
        <ClickAwayListener
          onClickAway={() => {
            if (clickAwayRef.current) return // Prevent duplicate execution
            clickAwayRef.current = true

            setTimeout(() => {
              clickAwayRef.current = false
            }, 200) // Debounce the click handling

            if (account.step === 1) {
              dispatch({ type: "HIDE_LOGIN_MODAL" })
            } else {
              setAccount({ ...account, step: 1 })
            }
          }}
        >
          <div className="inner">
            {account?.step === 2 ? (
              <ConfirmForm
                axios={axios}
                account={account}
                setAccount={setAccount}
                errors={errors}
                setErrors={setErrors}
              />
            ) : account?.step === 4 ? (
              <DifferentProvider
                axios={axios}
                account={account}
                setAccount={setAccount}
                errors={errors}
                setErrors={setErrors}
              />
            ) : account?.step === 5 ? (
              <ForgotForm
                axios={axios}
                account={account}
                setAccount={setAccount}
                errors={errors}
                setErrors={setErrors}
              />
            ) : (
              <IntroForm
                axios={axios}
                account={account}
                setAccount={setAccount}
                errors={errors}
                setErrors={setErrors}
              />
            )}
          </div>
        </ClickAwayListener>
      </Modal>
    )
  }
}

export default RegisterModal

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s ease-in-out;

  /* On mobile, anchor the modal to the bottom */
  @media (max-width: 768px) {
    align-items: flex-end;
  }

  .inner {
    width: 100%;
    max-width: 530px;
    max-height: calc(90vh - 40px);
    background: white;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: ${slideUp} 0.3s ease-in-out;
    overflow-y: auto;

    /* On mobile, use the bottom slide-up animation, full width, and remove bottom corner rounding */
    @media (max-width: 768px) {
      animation: ${slideUpBottom} 0.3s ease-in-out;
      width: 100%;
      max-width: 100%;
      border-radius: 15px 15px 0 0; /* Rounded top corners, square bottom corners */
    }
  }
`
