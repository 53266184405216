import { Context } from "@/context/state"
import translate from "@/utils/translation"

import {
  Dialog,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
  Slide,
} from "@mui/material"
import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import Script from "next/script"
import posthog from "posthog-js"
import React, { useContext, useEffect, useState } from "react"
import { CloseButton } from "react-bootstrap"
import styled from "styled-components"

const AppointmentModal = () => {
  const { data: session } = useSession()
  const { state, dispatch } = useContext(Context)
  const router = useRouter()

  const open = state?.appointment || false

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const setOpen = status =>
    dispatch({ type: "SET_APPOINTMENT", payload: status })

  const totalCommittedInvestment =
    session?.investments
      ?.filter(item => item.status === "committed")
      ?.reduce((sum, item) => sum + (item.investmentAmount || 0), 0) || 0

  const hasInvestments =
    session?.investments?.length > 0 &&
    session.investments.some(item => item.status === "committed") &&
    totalCommittedInvestment >= 1000

  const getTodayKey = () => {
    const today = new Date()
    return `introModalShown-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const todayKey = getTodayKey()
      const modalShownToday = localStorage.getItem(todayKey)

      if (session?.uid && !hasInvestments && !modalShownToday) {
        setOpen(true)
        localStorage.setItem(todayKey, "true")
      }
    }, 5000)

    return () => clearTimeout(timeout)
  }, [hasInvestments, session])

  const handleScheduleClick = e => {
    if (window.Calendly && router.locale !== "nl") {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/joep-valvest/introduction-to-valvest-clone?hide_gdpr_banner=1",
      })
    } else if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/joep-valvest/intro-valvest?hide_gdpr_banner=1",
      })
    }
  }
  if (hasInvestments) return null

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://assets.calendly.com/assets/external/widget.js"
      />
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />

      <Dialog
        fullScreen={isMobile}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setOpen(false)
          }
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        disableScrollLock
        PaperProps={{
          style: {
            position: isMobile ? "absolute" : "fixed",
            top: isMobile ? "auto" : "50%",
            left: isMobile ? 0 : "50%",
            transform: isMobile ? "none" : "translate(-50%, -50%)",
            bottom: isMobile ? 0 : "auto",
            width: isMobile ? "100%" : "500px",
            height: "auto",
            borderRadius: isMobile ? "20px 20px 0 0" : "20px",
            background: "#fb8500",
            display: "flex",
            flexDirection: "column",
            zIndex: 1301,
            margin: 0,
          },
        }}
      >
        <Wrapper>
          <div className="banner text-center">
            <CloseButton
              className="close"
              onClick={() => {
                posthog.capture("refuse_meeting", {
                  email: session?.email,
                  uid: session?.uid,
                })
                setOpen(false)
              }}
            />
            <h1>{translate("appointment_modal_title")}</h1>
          </div>
          <div className="inner">
            <h2>
              {session?.profile?.firstName}
              {translate("appointment_modal_subtitle")}
            </h2>
            <p>{translate("appointment_modal_text")}</p>
            <h3>{translate("appointment_modal_author")}</h3>
            <div className="d-flex mt-3">
              <div className="contact_item d-flex">
                <Avatar
                  src="/pages/about/employees/Joep-2.jpg"
                  alt="Joep"
                  sx={{ width: "50px", height: "50px" }}
                />
                <div className="ms-2 author">
                  <div className="name">Joep van der Laan</div>
                  <div className="title">
                    {translate("appointment_modal_CTO")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div>
              <Button
                variant="outlined"
                className="later"
                onClick={() => {
                  posthog.capture("refuse_meeting", {
                    email: session?.email,
                    uid: session?.uid,
                  })
                  setOpen(false)
                }}
              >
                {translate("appointment_modal_button_later")}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                className="now mt-3"
                onClick={() => {
                  posthog.capture("set_meeting", {
                    email: session?.email,
                    uid: session?.uid,
                  })
                  setOpen(false)
                  handleScheduleClick()
                }}
              >
                {translate("appointment_modal_button_now")}
              </Button>
            </div>
          </div>
        </Wrapper>
      </Dialog>
    </>
  )
}

export default AppointmentModal

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .banner {
    padding: 1rem 0 0.25rem;
    position: relative;

    h1 {
      font-weight: 600;
      color: white;
      font-size: 1rem;
    }

    .close {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .inner {
    background: white;
    padding: 2rem;
    border-radius: 20px 20px 0 0;

    h2 {
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
    }

    h3 {
      margin-top: 2rem;
      font-size: 0.875rem;
      line-height: 1.5;
      font-weight: 600;
    }

    p {
      font-size: 0.875rem;
    }

    .author {
      margin-top: 0.4rem;
    }

    .name {
      font-size: 0.875rem;
      font-weight: 700;
    }

    .title {
      font-size: 0.8rem;
      font-weight: 400;
    }
  }

  .buttons {
    padding: 2rem;
    background: #fbfaf8;
    margin-top: auto;

    .later {
      border: 1px solid #333;
      width: 100%;
      color: #333;
    }

    .now {
      margin-top: 0.5rem;
      width: 100%;
    }
  }
`
