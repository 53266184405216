import {
  ChevronLeft,
  X,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ArrowRight,
  Linkedin,
  Instagram,
} from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link, useRouter } from "next-translate-routes"
import {
  Button,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Slide,
} from "@mui/material"
import styled from "styled-components"
import Image from "next/image"
import ValvestLogo from "@/public/logo_valvest.svg"
import { useState, useEffect } from "react" // Import useEffect
import { TransitionGroup } from "react-transition-group"
import Line from "@/components/about-us/line"

const MobileMenuSlideout = ({ showMobile, setMobile, session }) => {
  const router = useRouter()

  // State to manage the current menu view
  const [currentMenu, setCurrentMenu] = useState("main")

  // State to manage which accordion panels are expanded
  const [expandedPanels, setExpandedPanels] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    aboutPanel1: false,
    aboutPanel2: false,
    aboutPanel3: false,
  })

  // State to manage the slide direction
  const [slideDirection, setSlideDirection] = useState("left") // Default direction

  // Reset expanded panels whenever currentMenu changes
  useEffect(() => {
    setExpandedPanels({})
  }, [currentMenu])

  // Function to navigate back to the main menu
  const handleBack = () => {
    setSlideDirection("right") // Set direction when navigating back
    setCurrentMenu("main")
  }

  const handleAccordionChange = panel => (event, isExpanded) => {
    if (panel.startsWith("aboutPanel")) {
      // For About menu panels, close all about panels except the one being toggled.
      setExpandedPanels(prev => ({
        ...prev,
        aboutPanel1: false,
        aboutPanel2: false,
        aboutPanel3: false,
        [panel]: isExpanded,
      }))
    } else {
      // For Knowledge menu panels, close all knowledge panels except the one being toggled.
      setExpandedPanels(prev => ({
        ...prev,
        panel1: false,
        panel2: false,
        panel3: false,
        [panel]: isExpanded,
      }))
    }
  }

  // Function to navigate to the knowledge menu
  const navigateToKnowledge = () => {
    setSlideDirection("left") // Set direction when navigating forward
    setCurrentMenu("knowledge")
  }

  // Function to navigate to the about menu
  const navigateToAbout = () => {
    setSlideDirection("left") // Set direction when navigating forward
    setCurrentMenu("about")
  }

  return (
    <Fade in={showMobile}>
      <MobileMenu onClick={() => setMobile(false)}>
        <div
          className="inner mobile text-start"
          onClick={e => e.stopPropagation()} // Prevent closing when clicking inside
        >
          <div className="d-flex ms-3 me-3 mt-2 mb-2 align-items-center justify-content-between">
            <Image
              src={ValvestLogo}
              alt="Valvest Logo"
              className="clickable"
              as="a"
              style={{ marginTop: "2px" }}
              width={160}
              onClick={() => {
                router.push("/"), setMobile(false)
              }}
            />

            <X
              onClick={() => setMobile(false)}
              className="closeMobile"
              size={30}
              color={"#fb8500"}
            />
          </div>
          <MenuContainer>
            <TransitionGroup component={null}>
              <Slide
                key={currentMenu} // Unique key based on currentMenu
                direction={slideDirection} // Dynamic direction
                in
                mountOnEnter
                unmountOnExit
                timeout={{
                  enter: 300,
                  exit: 300,
                }}
              >
                <div className="menu">
                  {currentMenu === "main" && (
                    <>
                      <div className="main-menu">
                        {!session?.profile && (
                          <div className="p-4 buttons">
                            <Button
                              variant="contained"
                              className="w-100"
                              onClick={() => {
                                router.push("/auth/register"), setMobile(false)
                              }}
                            >
                              {translate("menu_register")}
                            </Button>
                            <Button
                              variant="outlined"
                              className="w-100 mt-3"
                              onClick={() => {
                                router.push("/auth/login"), setMobile(false)
                              }}
                            >
                              {translate("menu_login")}
                            </Button>
                          </div>
                        )}
                        <Link
                          href={"/listings"}
                          onClick={() => setMobile(false)}
                        >
                          <div className="item">
                            {translate("menu_listings")}
                          </div>
                        </Link>
                        <div
                          className="item w-100 d-flex justify-content-between"
                          onClick={navigateToAbout} // New About Menu Item
                        >
                          {translate("menu_about")}
                          <ChevronRight className="icon mt-1" />
                        </div>
                        <div
                          className="item w-100 d-flex justify-content-between"
                          onClick={navigateToKnowledge}
                        >
                          {translate("account_menu_learn")}
                          <ChevronRight className="icon mt-1" />
                        </div>
                      </div>
                      <div className="social">
                        <h5>{translate("menu_jumbo_follow_us")}</h5>
                        <p>{translate("menu_jumbo_follow_us_description")}</p>
                        <div className="d-flex mt-2">
                          <a
                            href="https://www.linkedin.com/company/valvest-capital/"
                            target="_blank"
                            rel="noreferrer"
                            className="ps-0"
                          >
                            <Linkedin />
                          </a>
                          <a
                            href="https://www.instagram.com/valvest.io"
                            target="_blank"
                            rel="noreferrer"
                            className="ms-2"
                          >
                            <Instagram />
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  {currentMenu === "knowledge" && (
                    <div className="knowledge-menu">
                      <div className="item back" onClick={handleBack}>
                        <ChevronLeft className="icon me-2" />
                        {translate("account_knowledgehub_back")}
                      </div>

                      {/* Existing Knowledge Accordions */}
                      <Accordion
                        expanded={expandedPanels.panel1 || false}
                        onChange={handleAccordionChange("panel1")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.panel1 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_how_valvest_works")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                          <Typography>
                            {translate(
                              "menu_jumbo_how_valvest_works_description"
                            )}
                          </Typography>
                          <Link href="/blog" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_how_valvest_works")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3">
                            {translate("menu_jumbo_resources")}
                          </h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/blog/how-it-works">
                                {translate("menu_jumbo_how_valvest_works")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/blog/why-invest-in-real-estate">
                                {translate(
                                  "menu_jumbo_why_invest_with_valvest"
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link href="/blog/how-it-works#top-projects-selected-by-experts">
                                {translate("menu_jumbo_how_source_properties")}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expandedPanels.panel2 || false}
                        onChange={handleAccordionChange("panel2")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.panel2 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_learn")}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className="details">
                          <Typography>
                            {translate("menu_jumbo_learn_description")}
                          </Typography>
                          <Link href="/blog" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_learn")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3">
                            {translate("menu_jumbo_resources")}
                          </h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/blog/why-invest-in-real-estate">
                                {translate("menu_jumbo_learn_why_invest")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/blog/investing-in-real-estate-as-beginner">
                                {translate("menu_jumbo_learn_little_money")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/blog/how-we-develop-a-project-at-valvest">
                                {translate(
                                  "menu_jumbo_learn_how_valvest_develops"
                                )}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expandedPanels.panel3 || false}
                        onChange={handleAccordionChange("panel3")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.panel3 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_learn_faq")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                          <Typography>
                            {translate("menu_jumbo_learn_faq_description")}
                          </Typography>
                          <Link href="/helpcenter" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_faq_title")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3"></h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/helpcenter/article/213-how-long-until-i-get-my-money-back">
                                {translate("menu_jumbo_faq_how_long_locked")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/212-how-much-time-does-it-take-to-manage-my-investments">
                                {translate(
                                  "menu_jumbo_faq_how_much_time_management"
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/55-what-investements-do-we-offer">
                                {translate(
                                  "menu_jumbo_faq_what_investments_do_valvest_offer"
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/105-what-securities-do-i-have-at-valvest">
                                {translate("menu_jumbo_faq_what_securities")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/110-do-i-pay-taxes-over-my-interest-income">
                                {translate("menu_jumbo_faq_taxes")}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}

                  {currentMenu === "about" && (
                    <div className="about-menu">
                      <div className="item back" onClick={handleBack}>
                        <ChevronLeft className="icon me-2" />
                        {translate("menu_mobile_jumbo_back")}
                      </div>

                      {/* About Accordion Sections */}
                      <Accordion
                        expanded={expandedPanels.aboutPanel1 || false}
                        onChange={handleAccordionChange("aboutPanel1")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.aboutPanel1 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="aboutPanel1-content"
                          id="aboutPanel1-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_about_us")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                          <Typography>
                            {translate("menu_jumbo_about_description")}
                          </Typography>
                          <Link href="/about-us" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_about_us")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3">
                            {translate("menu_jumbo_resources")}
                          </h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/about-us#mission">
                                {translate("menu_jumbo_about_mission")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/about-us#team">
                                {translate("menu_jumbo_about_team")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/blog/valvest-investment-principles">
                                {translate("menu_jumbo_about_principles")}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expandedPanels.aboutPanel2 || false}
                        onChange={handleAccordionChange("aboutPanel2")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.aboutPanel2 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="aboutPanel2-content"
                          id="aboutPanel2-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_securities")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                          <Typography>
                            {translate("menu_jumbo_securities_description")}
                          </Typography>
                          <Link href="/securities" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_view_securities")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3">
                            {translate("menu_jumbo_resources")}
                          </h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/helpcenter/article/777-what-are-the-risks-when-investing-with-valvest">
                                {translate("menu_jumbo_securities_risks")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/266-do-i-become-an-owner-of-the-property-when-i-invest">
                                {translate("menu_jumbo_securities_owner")}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/107-what-happens-if-a-tenant-does-not-pay-rent">
                                {translate("menu_jumbo_securities_tenant")}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expandedPanels.aboutPanel3 || false}
                        onChange={handleAccordionChange("aboutPanel3")}
                        className="custom-accordion"
                      >
                        <AccordionSummary
                          expandIcon={
                            expandedPanels.aboutPanel3 ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          }
                          aria-controls="aboutPanel2-content"
                          id="aboutPanel2-header"
                        >
                          <Typography className="accordion-title">
                            {translate("menu_jumbo_trackrecord")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="details">
                          <Typography>
                            {translate("menu_jumbo_trackrecord_description")}
                          </Typography>
                          <Link href="/showcase" className="mt-3">
                            <Button variant="outlined" className="mt-3">
                              {translate("menu_jumbo_view_trackrecord")}
                              <ArrowRight className="icon ms-2" />
                            </Button>
                          </Link>
                          <Line className="mt-2" />
                          <h6 className="mt-3">
                            {translate("menu_jumbo_resources")}
                          </h6>
                          <ul className="resource-items">
                            <li>
                              <Link href="/helpcenter/article/75-who-handles-the-renovations">
                                {translate(
                                  "menu_jumbo_trackrecord_who_handles_renovations"
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/265-where-does-valvest-buys-its-properties">
                                {translate(
                                  "menu_jumbo_trackrecord_where_valvest_buys"
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link href="/helpcenter/article/264-how-did-valvest-start">
                                {translate(
                                  "menu_jumbo_trackrecord_how_did_valvest_start"
                                )}
                              </Link>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}
                </div>
              </Slide>
            </TransitionGroup>
          </MenuContainer>
        </div>
      </MobileMenu>
    </Fade>
  )
}

export default MobileMenuSlideout

// Styled Components
const MobileMenu = styled.div`
  background: rgba(0, 0, 0, 0.4); /* Corrected opacity value */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99999;
  text-align: left !important;
  display: flex;
  align-items: stretch;
  padding: 0;

  .inner {
    width: 100%;
    min-width: 300px;
    background: white;
    position: fixed; /* Ensures the menu stays fixed */
    top: 0px;
    height: 100%;
    overflow: hidden; /* Prevents layout shifts */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column; /* Ensure vertical stacking */
    height: 100vh;
  }

  .menu {
    position: fixed;
    width: 100%;
    background: white;
    top: 70px;
    overflow-y: auto; /* Allows scrolling if content overflows */
    padding-bottom: 1rem; /* Added padding for better spacing */
    display: flex;
    flex-direction: column; /* Stack menu items vertically */
  }

  .menu .item {
    padding: 1rem 1.5rem !important;
    color: rgba(18, 20, 23, 1) !important;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: space-between;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .back {
    justify-content: flex-start !important;
  }

  .item-project {
    font-weight: 500;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .buttons {
    color: rgba(18, 20, 23, 0.75) !important;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;
  }

  svg {
    margin-right: 0.75rem;
  }

  .register {
    margin-top: 1rem;
    padding: 1rem 1.5rem 1rem !important;
    border-bottom: 1px solid #e6e6e6;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    cursor: pointer;

    a {
      color: rgba(18, 20, 23, 0.75) !important;
      font-weight: 500;
    }
  }

  .name {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(18, 20, 23, 0.75) !important;
  }

  .id {
    margin-top: 0;
    font-size: 0.9rem;
    color: rgba(18, 20, 23, 0.5) !important;
  }

  .closeMobile {
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
  }

  hr {
    color: #a4a4a4;
  }

  /* Custom Accordion Styles */
  .custom-accordion {
    box-shadow: none;
    background: transparent;
    border: none;
    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0 2rem;
    }

    /* Add border-bottom to each accordion except the last one */
    & + & {
      border-top: 1px solid #e6e6e6;
    }
  }

  .accordion-title {
    font-size: 1rem;
    font-weight: 500;
    color: rgba(18, 20, 23, 1);
  }

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: 48px;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .MuiAccordionDetails-root {
    padding: 0 2rem 1rem 2rem; /* Adjusted padding for consistency */
    background: rgba(0, 0, 0, 0.02);
  }

  .Mui-expanded {
    margin: 0;
  }

  .sub-item {
    padding: 0.75rem 1.5rem;
    color: rgba(18, 20, 23, 0.75) !important;
    cursor: pointer;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 500;

    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  /* Additional Custom Accordion Styles */
  .custom-accordion {
    box-shadow: none;
    background: transparent;
    border: none;
    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 48px;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      border-bottom: 1px solid #e6e6e6;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .MuiAccordionSummary-expandIconWrapper {
      transform: none !important; /* Prevents icon shift on expand */
    }

    .MuiAccordionDetails-root {
      padding: 0 2rem 1rem 2rem; /* Adjusted padding for consistency */
      background: rgba(0, 0, 0, 0.02);
    }
  }

  .details {
    padding: 1.5rem 2rem !important;
    background: #f2f2f4;

    p {
      font-size: 0.875rem;
    }

    .resource-items {
      list-style: none;
      padding: 0;
      margin: 0;

      li,
      a {
        padding: 0.4rem 0;
        cursor: pointer;
        font-size: 0.75rem;
        color: rgb(21, 108, 216);
        text-decoration: underline;
        font-weight: 300;
      }
    }
  }

  .social {
    padding: 1rem;
    margin: 1rem 2.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;

    border-radius: 10px;

    background: #fceed0;
    margin-top: 1rem;

    p {
      font-size: 0.85rem;
      margin: 0 0 0.5rem 0;
    }

    a {
      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #333;
      }
    }
  }

  h6 {
    font-size: 0.75rem;
  }
`

const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative; /* Added to contain Slide components */
`
