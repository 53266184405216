import { Context } from "@/context/state"
import translate from "@/utils/translation"

import { Button, CircularProgress, Divider, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { X } from "react-bootstrap-icons"

import { FcGoogle } from "react-icons/fc"

import styled from "styled-components"
import { handleAccount } from "./actions"
import { toast } from "react-toastify"
import posthog from "posthog-js"
import { useSession } from "next-auth/react"
import PasswordInput from "../ConfirmForm/PasswordChecker"
import { useRouter } from "next-translate-routes"

const IntroForm = ({ account, setAccount, errors, setErrors, native }) => {
  const { dispatch } = useContext(Context)
  const { data: session } = useSession()
  const router = useRouter()

  const [showPassword, setPassword] = useState(false)

  const translation = {
    auth_required: translate("auth_validation_required"),
    auth_email: translate("login_label_error_email"),
    auth_password: translate("login_label_error_password"),
    auth_validation_already_exist: translate("auth_validation_already_exist"),
    auth_validation_error: translate("auth_validation_error"),
    auth_new_account_succesfull: translate("auth_new_account_succesfull"),
    auth_agree_terms_1: translate("auth_agree_terms_1"),
    auth_agree_terms_2: translate("auth_agree_terms_2"),
    auth_token_wrong: translate("auth_token_wrong"),
    login_succesful_title: translate("login_succesful_title"),
  }

  useEffect(() => {
    const handleAuthSuccess = event => {
      if (event.data?.event === "authSuccess") {
        // Proceed to the next step after successful authentication

        posthog.identify(account.email, {
          email: account?.email,
        })

        posthog.capture("sign_in", {
          email: account?.email,
        })

        toast.success("Succesvol ingelogd")
      } else if (event.data.event === "new") {
        posthog.capture("pre_sign_up", {
          email: account?.email,
        })

        setAccount({
          ...account,
          email: event.data.body.email,
          step: 2,
          provider: event.data.body?.provider,
          access_token: event.data.body?.access_token,
          image: event.data.body?.image || "",
        })
      } else if (event.data.event === "provider") {
        posthog.capture("other_sign_in", {
          email: account?.email,
        })

        setAccount({
          ...account,
          email: event.data.body.email,
          step: 4,
          provider: event.data.body.provider,
          image: event.data.body?.image,
        })
      }
    }

    window.addEventListener("message", handleAuthSuccess)
    return () => window.removeEventListener("message", handleAuthSuccess)
  }, [])

  return (
    <Content>
      <div className="header">
        <h1>{translate("account_auth_1_header")}</h1>
        {!native && (
          <Back
            onClick={() => dispatch({ type: "HIDE_LOGIN_MODAL" })}
            className="clickable"
            size={24}
          />
        )}
      </div>
      <hr />
      <div className="content">
        <h2>{translate("account_auth_1_subheader")}</h2>
        <div className="input mt-4">
          <TextField
            autoComplete="email"
            error={
              (account?.email?.length > 0 &&
                (account?.email?.length < 6 ||
                  !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                    account?.email
                  ))) ||
              errors?.email
            }
            helperText={errors?.email}
            style={{ zIndex: 0 }}
            onChange={e => {
              const email = e.target.value
              setAccount({ ...account, email })
            }}
            label={translate("auth_register_email")}
            fullWidth
            value={account?.email}
            aria-describedby="emailHelp"
            minLength={6}
          />
        </div>
        <div className="mt-2">
          <PasswordInput
            account={account}
            setAccount={setAccount}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <Button
          onClick={() =>
            handleAccount({
              setAccount,
              account,
              setErrors,
              translation,
              router,
            })
          }
          variant="contained"
          fullWidth
          className="rounded mt-3 p-2"
          disabled={account?.loading}
        >
          {account?.loading ? (
            <div className="loading">
              <CircularProgress color="primary" size="20px" className="mt-2" />
            </div>
          ) : (
            <>{translate("account_onboarding_next")}</>
          )}
        </Button>
        <div
          className="alternative mt-3 clickable"
          onClick={() => {
            setAccount({ ...account, step: 5 })
          }}
        >
          <>{translate("login_button_forgot_password")}</>
        </div>
        <Divider className="mt-4 divider">{translate("account_or")}</Divider>
        <Button
          variant="outlined"
          className="w-100 mt-3 sso-button"
          onClick={() =>
            window.open(
              "/auth/popup?type=google", // Use NextAuth provider route
              "Google Login",
              "width=600,height=600"
            )
          }
        >
          <div className="logo">
            <FcGoogle size={24} />
          </div>
          <div className="text">{translate("account_intro_google")} Google</div>
        </Button>
      </div>
    </Content>
  )
}

export default IntroForm

const Back = styled(X)`
  position: absolute;
  left: 20px;
  top: 22px;
  cursor: pointer;
`

const Content = styled.div`
  .header {
    padding: 1.5rem 0 0rem;
    text-align: center;

    h1 {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .content {
    padding: 1rem 1.5rem 1.75rem;
    h2 {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .divider {
    font-size: 0.875rem;
    color: rgb(106, 106, 106);
  }

  .alternative {
    color: gray;
    font-size: 0.875rem;
    text-decoration: underline;
  }

  .sso-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 402px;
    padding: 8px 16px;
    background: rgba(0, 0, 0, 0);
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: rgb(34, 34, 34);

    :hover {
      background-color: #eee;
    }

    .logo {
      position: absolute;
      left: 16px;
      top: 10px;
      display: flex;
      align-items: center;
    }

    .text {
      text-align: center;
      width: 100%;
    }
  }
`
